
export const serverUrl = !!window.cordova 
    ? 'https://maxapp.gal/api2'
    : ((window.location.protocol === 'http:') 
        ? 'http://localhost:4000' // Development
        : `${window.location.origin}/api2`); // Production

    
export const request = async function(resource)
{
    const requestOptions = 
    {
        headers: { 'Content-Type': 'application/json' },   
        credentials: "include",
    };
    let response = await fetch(`${serverUrl}${resource}`, requestOptions);
    return response;
};

export const post = async function(resource, body)
{
    const requestOptions = 
    {
        headers: { 'Content-Type': 'application/json' },   
        credentials: "include",
        method: 'POST',
        body: JSON.stringify(body),
    };
    let response = await fetch(`${serverUrl}${resource}`, requestOptions);
    return response;
};

export const upload = async function(resource, file)
{
    const requestOptions = 
    {
        credentials: "include",
        method: 'POST',
        body: file,
    };
    let response = await fetch(`${serverUrl}${resource}`, requestOptions);
    return response;
};

export const remove = async function(resource, body = {})
{
    const requestOptions = 
    {
        headers: { 'Content-Type': 'application/json' },   
        credentials: "include",
        method: 'DELETE',
        body: JSON.stringify(body)
    };
    let response = await fetch(`${serverUrl}${resource}`, requestOptions);
    return response;
};